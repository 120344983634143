import React, { useState } from 'react'
import { StyledCardsDocuments, StyledContainerDocuments, StyledContentDocuments, StyledTagsDocuments } from '../staff/Documents'
import CardDocumentsDinamic from './CardDocumentsDinamic';

const DocumentsDinamic = ({ data }) => {
  const [state, setState] = useState(data.map((item, i) => (
    {
      ...item,
      active: i === 0 ? true : false
    }
  )));

  const updateDataTags = (value) => {
    const newTags = state.map((tag) => {
      if (tag.hash === value) {
        return {
          ...tag,
          active: true
        }
      } else {
        return {
          ...tag,
          active: false
        }
      }
    })
    setState(newTags)
  }

  return (
    <StyledContainerDocuments>

      <StyledContentDocuments>
        <StyledTagsDocuments numbertags={state && state.length ? state.length : 0}>
          {
            state.map((tag, key) => (
              <div
                key={key}
                className={tag.active ? 'active' : ''}
                onClick={() => updateDataTags(tag.hash)}
              >
                <p>{tag.name}</p>
              </div>
            ))
          }
        </StyledTagsDocuments>
        <hr />
        <StyledCardsDocuments>
          {
            state.find(doc => doc.active === true).files.map((item, key) => {
              return (
                <CardDocumentsDinamic key={key} data={item} />
              )
            })
          }
        </StyledCardsDocuments>
      </StyledContentDocuments>
    </StyledContainerDocuments>
  )
}

export default DocumentsDinamic