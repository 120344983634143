import React from "react";

function DocumentsSvg({ color, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "21"}
      height={height ? height : "15"}
      fill="none"
      viewBox="0 0 21 15"
    >
      <path
        stroke={color}
        strokeMiterlimit="10"
        d="M16.169 14H2.88C1.841 14 1 13.26 1 12.345v-9.69C1 1.742 1.841 1 2.88 1h6.068c.152 0 .3.039.426.113l2.206 1.286a.848.848 0 00.426.113h4.163c1.038 0 1.88.742 1.88 1.655V7.08M16.479 14H3.33c-1.11 0-1.863-.992-1.439-1.896l1.962-4.178c.24-.513.81-.847 1.439-.847h13.15c1.11 0 1.863.992 1.44 1.896l-1.963 4.177c-.24.514-.81.848-1.439.848z"
      ></path>
    </svg>
  );
}

export default DocumentsSvg;
