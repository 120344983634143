import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom";
import { getResourceAction } from '../../actions';
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
import Loading from '../../components/loading';
import DocumentsDinamic from '../../components/documentsDinamic/DocumentsDinamic';

const Resource = () => {
  let { hash } = useParams();
  const location = useLocation();
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (hash) {
      getResourceAction(hash)
        .then((res) => {
          if (res[0]) {
            setData(null)
          } else {
            setData(res)
          }
        })
        .catch(() => {
          setData(null)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setData(null)
    }
  }, [location.pathname])

  return (
    <Layout>
      {
        loading ?
          <Loading /> :
          <>
            <Title text={data ? <span><b>{data.name}</b></span> : <span><b>Recurso no encontrado</b></span>} />
            <DocumentsDinamic data={data.tabs} />
            <style>
              {
                `
                  .form-close-modal > svg {
                      color: #FF671B !important;
                  }
                `
              }
            </style>
          </>
      }


    </Layout>
  )
}

export default Resource