import React, { useState } from 'react'
import styled from 'styled-components'
import CardDocuments from './CardDocuments'

export const StyledContainerDocuments = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px 0 0;
    
    h2 {
        color: #FF671B;
        margin: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0 40px 30px;
    }
`

export const StyledContentDocuments = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    
    hr {    
        width: 100%;
        border: none;
        height: 1px;  
        color: rgba(147, 147, 149, 0.80);
        background: rgba(0, 0, 0, 0.07);
        margin: 0;
    }
`

export const StyledTagsDocuments = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ numbertags }) => numbertags}, auto);
    grid-gap: 0;
    width: max-content;      

    > div {
        padding: 20px;
        cursor: pointer;

        p {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            color: #35374A;
            margin: 0;
        }

        &.active {
            p {
                font-weight: 700;                     
            }
        }

        &:hover {
            p {
                font-weight: 700;            
            }
        }
    }

    @media (max-width: 1350px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
`

export const StyledCardsDocuments = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const Documents = ({ data, notTitle, actionShow }) => {
    const [state, setState] = useState(data);

    const updateDataTags = (value) => {
        const newTags = state.map((tag) => {
            if (tag.value === value) {
                return {
                    ...tag,
                    active: true
                }
            } else {
                return {
                    ...tag,
                    active: false
                }
            }
        })
        setState(newTags)
    }

    return (
        <StyledContainerDocuments>
            {!notTitle && (<h2>Documentos</h2>)}
            <StyledContentDocuments>
                <StyledTagsDocuments numbertags={state && state.length ? state.length : 0}>
                    {
                        state.map((tag, key) => (
                            <div
                                key={key}
                                className={tag.active ? 'active' : ''}
                                onClick={() => updateDataTags(tag.value)}
                            >
                                <p>{tag.label}</p>
                            </div>
                        ))
                    }
                </StyledTagsDocuments>
                <hr />
                <StyledCardsDocuments>
                    {
                        state.find(doc => doc.active === true).data.map((item, key) => {
                            return (
                                <CardDocuments key={key} data={item} actionShow={actionShow} />
                            )
                        })
                    }
                </StyledCardsDocuments>
            </StyledContentDocuments>
        </StyledContainerDocuments>
    )
}

export default Documents