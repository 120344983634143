import React from 'react'
import { StyledContainerCardDocuments } from '../staff/CardDocuments'
import PdfSvg from '../svg/PdfSvg'
import Excel from '../icons/Excel'
import EyeSvg from '../svg/EyeSvg'
import Word from '../icons/Word'
import Mp4Svg from '../svg/Mp4Svg'
import DownLoadSvg from '../svg/DownLoadSvg'
import ImgIcon from '../icons/ImgIcon'
import DocumentsSvg from '../svg/DocumentsSvg'
import { useModal } from '../../hooks/context/modal'
import ReactPlayer from 'react-player'
import { StyledVideoModalFile } from '../../pages/tools/ContentRRSS'

export const RULES_FILES = {
  jpeg: {
    icon: <ImgIcon />,
    visible: true,
    modal: true,
    video: false
  },
  jpg: {
    icon: <ImgIcon />,
    visible: true,
    modal: true,
    video: false
  },
  png: {
    icon: <ImgIcon />,
    visible: true,
    modal: true,
    video: false
  },
  webp: {
    icon: <ImgIcon />,
    visible: true,
    modal: true,
    video: false
  },
  bmp: {
    icon: <ImgIcon />,
    visible: true,
    modal: true,
    video: false
  },
  mp4: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  avi: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  webm: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  wmv: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  mov: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  mpeg: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  mpg: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  mkv: {
    icon: <Mp4Svg />,
    visible: true,
    modal: true,
    video: true
  },
  pdf: {
    icon: <PdfSvg />,
    visible: true,
    modal: false,
    video: false
  },
  docx: {
    icon: <Word />,
    visible: false,
    modal: false,
    video: false
  },
  doc: {
    icon: <Word />,
    visible: false,
    modal: false,
    video: false
  },
  xls: {
    icon: <Excel />,
    visible: false,
    modal: false,
    video: false
  },
  xlsx: {
    icon: <Excel />,
    visible: false,
    modal: false,
    video: false
  }
}

const CardDocumentsDinamic = ({ data }) => {
  const { setOpen, setComponent } = useModal()

  const getExt = (file) => {
    let parts = file.split('.');
    return parts[parts.length - 1].toLowerCase();
  }

  const actionShow = (url, type) => {
    setComponent(selectComponent(url, type))
    setOpen(true)
  }

  const selectComponent = (url, video) => {
    if (video) {
      return (
        <StyledVideoModalFile>
          <ReactPlayer
            url={url}
            controls={true}
            playing={true}
            muted
            width='100%'
            height='100%'
            className="react-player"
            loop={true}
          />
        </StyledVideoModalFile>
      )
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '35px', overflow: 'auto' }}>
          <img src={url} alt="media" style={{ maxWidth: '100%' }} />
        </div>
      )
    }
  }

  return (
    <StyledContainerCardDocuments>
      <div>
        <div>
          {RULES_FILES[getExt(data.name)] ? RULES_FILES[getExt(data.name)].icon : <DocumentsSvg color="#FF671B" width={27} height={32} />}
        </div>
        <p>{data.name}</p>
      </div>
      <div>
        {
          RULES_FILES[getExt(data.name)] && RULES_FILES[getExt(data.name)].visible && (
            RULES_FILES[getExt(data.name)].modal ?
              <a href='#' onClick={() => actionShow(data.url, RULES_FILES[getExt(data.name)].video)}>
                <EyeSvg />
              </a>
              :
              <a href={data.url} rel="noopener noreferrer" target='_blank' >
                <EyeSvg />
              </a>
          )
        }
        <a href={data.url} download={data.name} target='_blank' rel="noopener noreferrer">
          <DownLoadSvg />
        </a>
      </div>
    </StyledContainerCardDocuments>
  )
}

export default CardDocumentsDinamic