import React, { useState } from 'react'
import styled from "styled-components";
import Layout from '../../components/layout';
import Cards from './Cards';
import { DATA_MARKETPLACE } from './data';
import { UseUser } from '../../hooks/context/user';
import { convertToSlug } from '../../libs/helpers/methods';
import CardContact from './CardContact';
import Header from './Header';

const StyledContent = styled.div`
    display: flex;
    gap: 20px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 33%;
    }

    @media (max-width: 1024px) {
        flex-wrap: wrap;

        > div {
            width: 48%;
        }
    }

    @media (max-width: 768px) {
        > div {
            width: 100%;
        }
    }
`

const Marketplace = () => {
    const { user } = UseUser()

    const { team } = user.profile
    const linkPintorist = team ? `https://pintorist.typeform.com/to/FnEyp9?pfichas=${convertToSlug(team)}` : ''
    const [data, setData] = useState(DATA_MARKETPLACE);

    const openCollapse = (option) => {
        const newData = data.map((item, i) => {
            if (option === i) {
                return {
                    ...item,
                    active: !item.active
                }
            }
            return item
        })
        setData(newData)
    }

    return (
        <Layout>
            <Header />
            <StyledContent>
                <div>
                    {
                        data.filter((mark) => mark.position === 'first').map((item, key) => {
                            return (
                                <Cards
                                    data={item}
                                    number={key}
                                    openCollapse={openCollapse}
                                    linkPintorist={linkPintorist}
                                    key={key}
                                />
                            )
                        })
                    }
                </div>
                <div>
                    {
                        data.filter((mark) => mark.position === 'second').map((item, key) => {
                            return (
                                <Cards
                                    data={item}
                                    number={data.filter((item) => item.position === 'first').length + key}
                                    openCollapse={openCollapse}
                                    linkPintorist={linkPintorist}
                                    key={key}
                                />
                            )
                        })
                    }
                </div>
                <div>
                    {
                        data.filter((mark) => mark.position === 'three').map((item, key) => {
                            return (
                                <Cards
                                    data={item}
                                    number={data.filter((item) => item.position === 'first').length + data.filter((item) => item.position === 'second').length + key}
                                    openCollapse={openCollapse}
                                    linkPintorist={linkPintorist}
                                    key={key}
                                />
                            )
                        })
                    }
                    <CardContact />
                </div>
            </StyledContent>
            <style>
                {
                    `
                        .form-close-modal > svg {
                            color: #000000 !important;
                        }
                    `
                }
            </style>
        </Layout>
    )
}

export default Marketplace