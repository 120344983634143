import React from 'react'
import styled from 'styled-components';
import NewsLastMonth from './NewsLastMonth';

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 10px;
    width: calc(100% - 10px);
    margin-bottom: 10px;

    img {
        width: 100%;
        max-width: 100%;
    }

    > div:nth-child(1) {
        display: flex;
    }

    @media (max-width: 1350px) {
        grid-template-columns: 100%;
        width: 100%;
    }
`

const ContainerHallFame = ({ permits }) => {
    return (
        <StyledContainer>
            <div>
                {
                    permits.hall_fame_last_month && (
                        <img src="/static/home/hall-fame.webp" alt='hall-fame' />
                    )
                }
            </div>
            <div>
                {
                    permits.news_last_month && (
                        <NewsLastMonth />
                    )
                }
            </div>
        </StyledContainer>
    )
}

export default ContainerHallFame